import { RichTextComponent } from '@msdyn365-commerce/core';
import { IFooterSectionViewProps } from './footer-section';
import React from 'react';

export default (props: IFooterSectionViewProps) => {
    const [expandImageDisclaimer, setExpandImageDisclaimer] = React.useState(false);

    return (
        <footer aria-label='Footer' className='Footer'>
            <div className='Footer-CmsBlockWrapper Footer-Content'>
                <div className='Footer-Links ContentWrapper'>
                    <div className='CmsBlock-Wrapper CmsBlock-Wrapper_type_'>
                        {props.config.footerCategories?.map((category, categoryIndex) => {
                            const checkboxId = `check${categoryIndex + 1}`;
                            return (
                                <div key={categoryIndex} className='column'>
                                    <input id={checkboxId} type='checkbox' />
                                    <label className='title' htmlFor={checkboxId}>
                                        {category.title}
                                    </label>
                                    <ul>
                                        {category.subCategories?.map((subCategory, subIndex) => {
                                            return (
                                                <li key={subIndex}>
                                                    <a title='Delivery' href={subCategory.link?.destinationUrl} className=' '>
                                                        {subCategory.linkText}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className='Footer-NeedHelpCMS'>
                <div className='CmsBlock-Wrapper CmsBlock-Wrapper_type_'>
                    <div
                        data-content-type='row'
                        data-appearance='full-width'
                        data-enable-parallax='0'
                        data-parallax-speed='0.5'
                        data-background-images='{}'
                        data-background-type='image'
                        data-video-loop='true'
                        data-video-play-only-visible='true'
                        data-video-lazy-load='true'
                        data-video-fallback-src=''
                        data-element='main'
                        style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundPosition: 'left top',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundAttachment: 'scroll',
                            borderStyle: 'none',
                            borderWidth: '1px',
                            borderRadius: '0px',
                            margin: '0px 0px 10px',
                            padding: '10px'
                        }}
                    >
                        <div className='row-full-width-inner' data-element='inner'>
                            <figure
                                data-content-type='image'
                                data-appearance='full-width'
                                data-element='main'
                                style={{
                                    margin: '0px',
                                    padding: '0px',
                                    borderStyle: 'none'
                                }}
                            >
                                <img
                                    className='pagebuilder-mobile-hidden'
                                    src={props.config.needHelpIcon?.src}
                                    alt={props.config.needHelpIcon?.altText}
                                    title=''
                                    data-element='desktop_image'
                                    style={{
                                        borderStyle: 'none',
                                        borderWidth: '1px',
                                        borderRadius: '0px',
                                        maxWidth: '100%',
                                        height: 'auto'
                                    }}
                                />
                                <img
                                    className='pagebuilder-mobile-only'
                                    src={props.config.needHelpIcon?.src}
                                    alt={props.config.needHelpIcon?.altText}
                                    title=''
                                    data-element='mobile_image'
                                    style={{
                                        borderStyle: 'none',
                                        borderWidth: '1px',
                                        borderRadius: '0px',
                                        maxWidth: '100%',
                                        height: 'auto'
                                    }}
                                />
                            </figure>
                            <div
                                data-content-type='text'
                                data-appearance='default'
                                data-element='main'
                                style={{
                                    borderStyle: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '0px',
                                    margin: '0px',
                                    padding: '0px'
                                }}
                            >
                                <p>{props.config.needHelp?.title}</p>
                            </div>
                            <div
                                data-content-type='text'
                                data-appearance='default'
                                data-element='main'
                                style={{
                                    borderStyle: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '0px',
                                    margin: '0px',
                                    padding: '0px'
                                }}
                            >
                                <p>
                                    <RichTextComponent text={props.config.needHelp?.description} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Footer-ImageDisclaimer'>
                <div className='SpImageDisclaimer'>
                    <article className='ExpandableContent ImageDisclaimer-ExpandableContent'>
                        {/* ExpandableContent-Button ExpandableContent-Button_isContentExpanded ImageDisclaimer-ExpandableContentButton */}
                        <button
                            onClick={() => setExpandImageDisclaimer(!expandImageDisclaimer)}
                            className={`ExpandableContent-Button ImageDisclaimer-ExpandableContentButton ${
                                expandImageDisclaimer ? 'ExpandableContent-Button_isContentExpanded ' : ''
                            }`}
                        >
                            <span className='ExpandableContent-Heading ImageDisclaimer-ExpandableContentHeading'>
                                {props.config.imageDisclaimer?.title}
                            </span>
                            <span className='ExpandableContent-SubHeading ImageDisclaimer-ExpandableContentSubHeading'></span>
                        </button>
                        {/* ExpandableContent-Content ExpandableContent-Content_isContentExpanded ImageDisclaimer-ExpandableContentContent ImageDisclaimer-ExpandableContentContent_isContentExpanded */}
                        <div
                            className={`ExpandableContent-Content  ImageDisclaimer-ExpandableContentContent  ${
                                expandImageDisclaimer
                                    ? 'ExpandableContent-Content_isContentExpanded  ImageDisclaimer-ExpandableContentContent_isContentExpanded'
                                    : ''
                            }`}
                        >
                            <div className='CmsBlock-Wrapper CmsBlock-Wrapper_type_'>
                                <div data-content-type='row' data-appearance='contained' data-element='main'>
                                    <div
                                        data-enable-parallax='0'
                                        data-parallax-speed='0.5'
                                        data-background-images='{}'
                                        data-background-type='image'
                                        data-video-loop='true'
                                        data-video-play-only-visible='true'
                                        data-video-lazy-load='true'
                                        data-video-fallback-src=''
                                        data-element='inner'
                                        style={{
                                            justifyContent: 'flex-start',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundPosition: 'left top',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundAttachment: 'scroll',
                                            borderStyle: 'none',
                                            borderWidth: '1px',
                                            borderRadius: '0px',
                                            margin: '0px',
                                            padding: '0px'
                                        }}
                                    >
                                        <div
                                            data-content-type='text'
                                            data-appearance='default'
                                            data-element='main'
                                            style={{
                                                borderStyle: 'none',
                                                borderWidth: '1px',
                                                borderRadius: '0px',
                                                margin: '0px',
                                                padding: '0px'
                                            }}
                                        >
                                            <p>
                                                <RichTextComponent text={props.config.imageDisclaimer?.description} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            <div className='Footer-BottomCMS'>
                <div className='CmsBlock-Wrapper CmsBlock-Wrapper_type_'>
                    <div
                        data-content-type='row'
                        data-appearance='contained'
                        data-element='main'
                        data-enable-parallax='0'
                        data-parallax-speed='0.5'
                        data-background-images={{}}
                        data-background-type='image'
                        data-video-loop={true}
                        data-video-play-only-visible={true}
                        data-video-lazy-load={true}
                        data-video-fallback-src=''
                        style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundPosition: 'left top',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundAttachment: 'scroll',
                            borderStyle: 'none',
                            borderWidth: '1px',
                            borderRadius: '0px',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        <div
                            data-content-type='text'
                            data-appearance='default'
                            data-element='main'
                            style={{
                                borderStyle: 'none',
                                borderWidth: '1px',
                                borderRadius: '0px',
                                margin: '0px',
                                padding: '0px'
                            }}
                        >
                            <p style={{ textAlign: 'center' }}>
                                <img
                                    src={props.config.footerImage?.src}
                                    alt={props.config.footerImage?.altText}
                                    width='174'
                                    height='54'
                                    style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                />
                            </p>
                            <p className='custom-color' style={{ textAlign: 'center', color: '#fff' }}>
                                <RichTextComponent text={props.config.footerDescription} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Footer-BottomCMS'>
                <div className='CmsBlock-Wrapper CmsBlock-Wrapper_type_'>
                    <section aria-label='' className='Footer-CopyrightContentWrapper'>
                        <div className='ContentWrapper Footer-CopyrightContent'>
                            <div className='Footer-Copyright custom-color' style={{ color: '#ffffff' }}>
                                <RichTextComponent text={props.config.copyright} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </footer>
    );
};
